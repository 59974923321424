.animationWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.backLayer {
  position: absolute;
  top: 0;
  right: 0;
  width: 51.1%;
}

.backLayerContent {
  aspect-ratio: 570/565;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url('/rive/hero/move/secondary-animation-video-member-exercise/image-member-exercise-frame.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  video {
    height: 95%;
    left: 2.5%;
    top: 2.5%;
    width: 95%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
  }
}

.middleLayer {
  position: absolute;
  width: 61.8%;
  bottom: 27%;
  left: 0;

  video {
    width: 100%;
    overflow: hidden;
    border-radius: 10%;
    display: block;
    aspect-ratio: 666.3/320;
    padding: 1px;
    z-index: -1;
  }
}

.frontLayer {
  position: absolute;
  bottom: 7%;
  left: 48.5%;
  width: 25%;
  height: 38.4%;
}

.frontLayerContent {
  height: 100%;
  width: 100%;
}
