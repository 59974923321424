.layerContent {
  width: 100%;
  height: 100%;
}

.backLayer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.backLayerContent {
  aspect-ratio: 938 / 660;
  left: 5%;
  position: absolute;
  width: 90%;

  video {
    height: 85%;
    left: 5%;
    top: 8%;
    width: 90%;
    object-fit: cover;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}

.secondaryAnimation {
  position: absolute;
  bottom: 27%;
  left: 13%;
  width: 18%;
  height: 41%;
}

.secondaryAnimationVideo {
  width: 100%;
  border-radius: 11px;

  @media (min-width: token(breakpoints.minMd)) {
    border-radius: 22px;
  }
}
.secondaryAnimationPhoenix {
  position: absolute;
  left: 0;
  bottom: 10%;
  z-index: 2;
  width: 100%;
  height: 13%;
}

.frontLayer {
  position: absolute;
  right: 4%;
  bottom: 16%;
  z-index: 2;
  width: 30%;
  height: 60%;
}
