.wraper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: clip;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @media (min-width: token(breakpoints.minLg)) {
    gap: 32px;
  }
}

@keyframes lineAnimation {
  0% {
    width: 640px;
    mask-size: 0% 100%;
  }
  100% {
    width: 640px;
    mask-size: 600% 100%;
  }
}

.line {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border: 1px dashed transparent;
  border-image: repeating-linear-gradient(
      to right,
      var(--ui-colors-white) 0,
      var(--ui-colors-white) 15px,
      transparent 15px,
      transparent 30px
    )
    1;
  width: 0;
  height: 1px;
  mask-image: radial-gradient(circle, var(--ui-colors-white) 0%, transparent 100%);
  mask-position: center;
  mask-repeat: no-repeat;

  @media (prefers-reduced-motion: no-preference) {
    &.showAnimation {
      animation: lineAnimation 800ms ease-in-out forwards;
    }
    @media (min-width: token(breakpoints.minMd)) {
      animation: lineAnimation 800ms ease-in-out forwards;
    }
  }
  @media (prefers-reduced-motion: reduce) {
    width: 100%;
  }
}
.lineOne {
  top: 25%;
}
.lineTwo {
  top: 50%;
}
.lineThree {
  top: 75%;
}
.lineFour {
  bottom: 0;
  border-image: none;
  border: 1px solid var(--ui-colors-white);
  width: 100%;
  animation: none;
  mask-image: none;
}
.bar {
  width: 100%;
  height: 100%;
  max-width: 110px;
  min-width: 104px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  flex-direction: column;
  padding-bottom: 2px;

  @media (min-width: token(breakpoints.minLg)) {
    max-width: 165.28px;
  }
}
@keyframes barsAnimation {
  0% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}
.barAnimation {
  width: 80px;
  height: 0%;
  border-radius: 16px 16px 0 0;
  background-color: var(--ui-colors-white);
  overflow: hidden;
  position: relative;

  @media (min-width: token(breakpoints.minLg)) {
    width: 100%;
  }

  @media (prefers-reduced-motion: reduce) {
    height: 100%;
  }
}
@keyframes backgroundAnimationLeftMobile {
  0% {
    transform: translate3d(-770%, 110%, -1px);
  }
  50% {
    transform: translate3d(49%, 8%, -1px);
  }
  100% {
    transform: translate3d(-770%, 110%, -1px);
  }
}
@keyframes backgroundAnimationLeft {
  0% {
    transform: translate3d(-400%, 115%, -1px);
  }
  50% {
    transform: translate3d(49%, 8%, -1px);
  }
  100% {
    transform: translate3d(-400%, 115%, -1px);
  }
}
@keyframes backgroundAnimationRightMobile {
  0% {
    transform: translate3d(-770%, 80%, -1px);
  }
  50% {
    transform: translate3d(49%, 8%, -1px);
  }
  100% {
    transform: translate3d(-770%, 80%, -1px);
  }
}
@keyframes backgroundAnimationRight {
  0% {
    transform: translate3d(-397%, 46%, -1px);
  }
  50% {
    transform: translate3d(49%, 8%, -1px);
  }
  100% {
    transform: translate3d(-397%, 46%, -1px);
  }
}
.barAnimationLeft {
  max-height: 25%;

  @media (prefers-reduced-motion: no-preference) {
    &.showAnimation {
      animation: barsAnimation 2800ms 900ms ease-in-out forwards;
    }
    @media (min-width: token(breakpoints.minMd)) {
      animation: barsAnimation 2800ms 900ms ease-in-out forwards;
    }
  }

  > .backgroundAnimation {
    transform: translate3d(-770%, 110%, -1px);

    @media (prefers-reduced-motion: no-preference) {
      &.showAnimation {
        animation: backgroundAnimationLeftMobile 20s 3300ms ease-in-out infinite;
      }
      @media (min-width: token(breakpoints.minMd)) {
        animation: backgroundAnimationLeftMobile 20s 3300ms ease-in-out infinite;
      }
    }

    @media (min-width: token(breakpoints.minLg)) {
      transform: translate3d(-400%, 115%, -1px);

      @media (prefers-reduced-motion: no-preference) {
        &.showAnimation {
          animation: backgroundAnimationLeft 20s 3300ms ease-in-out infinite;
        }
        @media (min-width: token(breakpoints.minMd)) {
          animation: backgroundAnimationLeft 20s 3300ms ease-in-out infinite;
        }
      }
    }
  }
}
.barAnimationRight {
  max-height: 75%;

  @media (prefers-reduced-motion: no-preference) {
    &.showAnimation {
      animation: barsAnimation 1400ms 900ms ease-in-out forwards;
    }
    @media (min-width: token(breakpoints.minMd)) {
      animation: barsAnimation 1400ms 900ms ease-in-out forwards;
    }
  }

  > .backgroundAnimation {
    transform: translate3d(-770%, 80%, -1px);

    @media (prefers-reduced-motion: no-preference) {
      &.showAnimation {
        animation: backgroundAnimationRightMobile 20s 3300ms ease-in-out infinite;
      }
      @media (min-width: token(breakpoints.minMd)) {
        animation: backgroundAnimationRightMobile 20s 3300ms ease-in-out infinite;
      }
    }

    @media (min-width: token(breakpoints.minLg)) {
      transform: translate3d(-397%, 46%, -1px);

      @media (prefers-reduced-motion: no-preference) {
        &.showAnimation {
          animation: backgroundAnimationRight 20s 3300ms ease-in-out infinite;
        }
        @media (min-width: token(breakpoints.minMd)) {
          animation: backgroundAnimationRight 20s 3300ms ease-in-out infinite;
        }
      }
    }
  }
}

@keyframes labelAnimation {
  0% {
    opacity: 0;
    transform: translateY(8px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.label {
  background-color: var(--ui-colors-white);
  box-shadow: var(--ui-shadow-card-default);
  border-radius: 16px;
  padding: 8px 16px;
  font-family: var(--ui-font-family-heading);
  font-size: 13px;
  font-weight: var(--ui-font-weight-semibold);
  color: var(--ui-colors-grey-800);
  line-height: 16.9px;
  text-align: center;
  white-space: break-spaces;
  opacity: 0;
  transform: translateY(8px);

  @media (prefers-reduced-motion: no-preference) {
    &.showAnimation {
      animation: labelAnimation 800ms 2000ms ease-in-out forwards;
    }
    @media (min-width: token(breakpoints.minMd)) {
      animation: labelAnimation 800ms 2000ms ease-in-out forwards;
    }
  }

  @media (prefers-reduced-motion: reduce) {
    opacity: 1;
    transform: translateY(0px);
  }
}
.backgroundAnimation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.circle {
  width: 636px;
  height: 635px;
  position: absolute;
  border-radius: 100%;
  filter: blur(100px);
  opacity: 0.4;

  @media (hover: none) and (pointer: coarse) {
    will-change: transform;
  }
}
.circleOne {
  background-color: var(--ui-colors-primary-default);
  left: 54px;
  top: -108px;
}
.circleTwo {
  background: linear-gradient(
    94.39deg,
    var(--ui-colors-negative-600) 2.87%,
    var(--ui-colors-primary-default) 99.25%
  );
  top: 105px;
  left: -176px;
}
.circleThree {
  background-color: var(--ui-colors-negative-600);
  top: 5px;
  left: 308px;
}
