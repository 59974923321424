@keyframes sliderAnimation {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animationSlide {
  --animation-solutions-slide-transform: translate3d(
    calc(5rem * var(--animation-solutions-direction)),
    0,
    0
  );
  --animation-solutions-slide-delay: 0s;
  --animation-solutions-slide-duration: 0.3s;
  --animation-solutions-slide-easing: ease-in;
  opacity: 0;

  [data-playing='true'] & {
    opacity: 1;
  }

  @media (prefers-reduced-motion: no-preference) {
    transition: all var(--animation-solutions-slide-duration)
      var(--animation-solutions-slide-easing) var(--animation-solutions-slide-delay);
    transform: var(--animation-solutions-slide-transform);

    [data-playing='true'] & {
      --animation-solutions-slide-duration: 1s;
      --animation-solutions-slide-easing: ease-out;
      transform: translate3d(0, 0, 0);

      [data-atropos-offset='-1'] & {
        --animation-solutions-slide-delay: 0.1s;
      }

      [data-atropos-offset='1'] & {
        --animation-solutions-slide-delay: 0.2s;
      }

      [data-atropos-offset='3'] & {
        --animation-solutions-slide-delay: 0.3s;
      }
    }
  }
}

.slider {
  --slider-flex-direction: column-reverse;
  overflow: visible;
  height: 100%;
  width: 100%;

  :global(.swiper) {
    flex-grow: 1;
    overflow: visible;
  }

  @media (prefers-reduced-motion: no-preference) {
    :global(.swiper) {
      opacity: 0;
      transform: translateY(3rem);

      &:global(.swiper-initialized) {
        opacity: 1;
        transform: translateY(0);
        transition: all var(--ui-transitions-duration-long) var(--ui-transitions-timing-ease-in-out);
      }
    }
  }
}
