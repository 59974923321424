.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.backLayer {
  position: absolute;
  top: 12%;
  right: 0;
  width: 71.8%;
}

.backLayerContent {
  aspect-ratio: 938 / 660;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url('/rive/hero/bloom/secondary-animation-member-exercise/image-member-exercise-frame.png');
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  video {
    border-radius: 10px;
    height: 95%;
    left: 2.5%;
    top: 2.5%;
    width: 95%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
  }
}

.middleLayer {
  position: absolute;
  width: 34.1%;
  height: 87.5%;
  top: 0;
  left: 0;

  .middleLayerContent {
    height: 100%;
    width: 100%;
  }
}

.frontLayer {
  position: absolute;
  bottom: 0;
  left: 10.7%;
  width: 46.7%;
  aspect-ratio: 899 / 447;

  img {
    object-fit: contain;
    object-position: center bottom;
  }
}
